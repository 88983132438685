import Camera from '../components/Camera'
import SceneCanvas from '../components/SceneCanvas'
import SceneEnvironment from '../components/SceneEnvironment'
import { Html, Image } from '@react-three/drei'
import { useMemo } from 'react'
import SantaFigure from '../components/SantaFigure'
import CollectibleItem from '../components/CollectibleItem'
import { useSelector } from 'react-redux'
import * as Constants from '../Constants'
import InformationPromptView from '../views/InformationPromptView'

export default function MilkaTestScene({ children }) {
    const cameraMode = useSelector(state => state.camera.mode)
    const language = useSelector(state => state.language.value)
    const isWinner = useSelector(state => state.user.isWinner)
    const isGiver = useSelector(state => state.user.isGiver)
    const noFreeSantas = useSelector((state) => state.walkthrough.noFreeSantas);

    const cameraInitialAngles = useMemo(() => {
        return Constants.CameraInitialAngles[language]
    }, [language])

    return (
        <>
            <SceneCanvas startPosition={ [0, 2, 0] }>
                <Camera 
                    horizontalAngle={ cameraInitialAngles.horizontal } 
                    verticalAngle={ cameraInitialAngles.vertical }
                />

                <ambientLight />

                <SceneEnvironment texture="/textures/scene15.jpg" />

                { language == 'cs' && <SantaFigure /> }


                { language == 'cs' && <>
                    <CollectibleItem position={ [4.65, -1.2, -.85] } rotation={ -.3 } />
                    <CollectibleItem position={ [-.3, -1, 8.5] } rotation={ .7 } />
                    <CollectibleItem position={ [-5.27, -1, 9] } rotation={ .8 } />
                    <CollectibleItem position={ [-8, -1, -4.5] } rotation={ .4 } />
                    <CollectibleItem position={ [.75, -1, -8.2] } rotation={ .1 } />
                </> }

                { language == 'de' && cameraMode != Constants.CameraModes.Rotating &&
                    <group position= { [29.9, 5.95, -.70] } rotation={ [0, -Math.PI / 2, 0] }> 
                        <Html transform>
                            { children }
                        </Html>

                        { (isWinner && !noFreeSantas) && <Html transform position={[0, -16.5, 1]} >
                            <InformationPromptView isGiver={ isGiver } />
                        </Html> }
                    </group>
                }
            </SceneCanvas>
        </>
    )
}
