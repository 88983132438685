// import '../../styles/InformationPromptView.css'

export default function InformationPromptView({ isGiver }) {
    return (
        <>
            <div id="information-prompt">
                <div id="icon"></div>
                { isGiver && 
                    <>
                        Die Person, die diese Nachricht<br />
                        erhält, bekommt einen gratis <br />
                        Milka Weihnachtsmann.
                    </>
                }

                { !isGiver && 
                    <>
                        Schaue Dir die ganze Nachricht <br />
                        an um Deinen gratis Milka <br />
                        Weihnachtsmann zu erhalten.
                    </>
                }
                
            </div>
        </>
    )
}
