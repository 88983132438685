// import '../../styles/LoadingOverlay.css'
import { useDispatch } from 'react-redux'
import { changeCameraModeToOrbit } from '../slices/cameraSlice'
import { setIsStarted } from '../slices/userSlice'
import { useState, useEffect } from 'react'

export default function LoadingOverlay() {
    const [inExperience, setIsExperience] = useState(false);
    const [fadingOut, setFadingOut] = useState(false);
    const dispatch = useDispatch();
    const TRANSITION_DURATION = 1500;

    const isiOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    useEffect(() => {
        dispatch(setIsStarted(false));
    }, [dispatch]);

    const startExperience = function () {
        dispatch(changeCameraModeToOrbit());

        if (!isiOS()) {
            setFadingOut(true);
            setTimeout(() => {
                setIsExperience(true);
                dispatch(setIsStarted(true));
            }, TRANSITION_DURATION);
        } else {
            setIsExperience(true);
            dispatch(setIsStarted(true));
        }
    };

    return (
        <>
            {!inExperience && (
                <div
                    id="loading-overlay"
                    className={`${!isiOS() && fadingOut ? 'blur-out' : ''}`}
                    style={!isiOS() ? { '--transition-duration': `${TRANSITION_DURATION}ms` } : {}}
                >
                    <button onClick={startExperience}>Start</button>
                </div>
            )}
        </>
    );
}
