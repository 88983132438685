import { useFrame, useLoader } from '@react-three/fiber'
import { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { GLTFLoader } from 'three/examples/jsm/Addons.js'
import * as THREE from 'three'

export default function SantaFigure() 
{
    const visible = useSelector(state => state.santaFigure.visible)
    const b1 = useSelector(state => state.santaFigure.b1)
    const b2 = useSelector(state => state.santaFigure.b2)
    const b3 = useSelector(state => state.santaFigure.b3)
    const b4 = useSelector(state => state.santaFigure.b4)
    const b5 = useSelector(state => state.santaFigure.b5)
    const b6 = useSelector(state => state.santaFigure.b6)
    const b7 = useSelector(state => state.santaFigure.b7)
    const b8 = useSelector(state => state.santaFigure.b8)
    const b9 = useSelector(state => state.santaFigure.b9)

    const santa = useRef()

    const santaModel = useMemo(() => {
        const fullPath = `/models/santa.glb`
        const gltf = useLoader(GLTFLoader, fullPath)
        return gltf.scene.clone(true)
    }, [])

    useEffect(() => {
        santaModel.getObjectByName('Santa_Reloaded001').morphTargetInfluences[0] = b1
        santaModel.getObjectByName('Santa_Reloaded001').morphTargetInfluences[1] = b2
        santaModel.getObjectByName('Santa_Reloaded001').morphTargetInfluences[2] = b3
        santaModel.getObjectByName('Santa_Reloaded001').morphTargetInfluences[3] = b4
        santaModel.getObjectByName('Santa_Reloaded001').morphTargetInfluences[4] = b5
        santaModel.getObjectByName('Santa_Reloaded001').morphTargetInfluences[5] = b6
        santaModel.getObjectByName('Santa_Reloaded001').morphTargetInfluences[6] = b7
        santaModel.getObjectByName('Santa_Reloaded001').morphTargetInfluences[7] = b8
        santaModel.getObjectByName('Santa_Reloaded001').morphTargetInfluences[8] = b9
    }, [b1, b2, b3, b4, b5, b6, b7, b8, b9])

    useFrame((_, delta) => {
        if (!visible) {
            santa.current.scale.lerp(new THREE.Vector3(0, 0, 0), Math.LN2 * delta * 3)
            santa.current.rotation.y = THREE.MathUtils.lerp(santa.current.rotation.y, -Math.PI * 2, delta)
        }
    })

    return (
        <primitive 
            object={ santaModel } 
            scale={ .6 }
            ref={ santa }
            position={ [-3, -1, 0] }
            rotation-y={ Math.PI / 2 }
        />
    ) 
}
